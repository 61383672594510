


















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class DropdownLanguage extends Vue {
  @Prop({ default: webpack.FEATURES.languages[0] })
  public value!: string;

  public get languages() {
    return webpack.FEATURES.languages.map(key => ({
      key,
      label: this.$t('language-' + key),
    }));
  }

  public onInput(e: Event) {
    const input = e.target as HTMLBceSelectElement;
    this.$emit('input', input.value as string);
  }
}
